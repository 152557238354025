/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    strong: "strong"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "covid-19-and-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#covid-19-and-hearing-loss",
    "aria-label": "covid 19 and hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Covid 19 and hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As the world continues to grapple with the aftereffects of the COVID-19 pandemic, researchers have been uncovering a myriad of symptoms and long-term reverberations associated with the virus. While the most common symptoms include fever, cough, and difficulty breathing, COVID-19's impact is not limited to the respiratory system. Emerging evidence suggests a potential link between the virus and long-term hearing-related issues, such as ear pain, pressure, and ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/",
    className: "c-md-a"
  }, "hearing loss"), ". In this article, we will explore the connection between COVID hearing loss, shedding light on the importance of addressing these issues."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "covid-19-symptoms-and-hearing-related-issues",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#covid-19-symptoms-and-hearing-related-issues",
    "aria-label": "covid 19 symptoms and hearing related issues permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "COVID-19 Symptoms and Hearing-Related Issues"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "COVID-19 presents itself in a multitude of ways, with symptoms varying from person to person. While the most frequently reported symptoms are a fever, a cough, and a loss of taste or smell, some individuals have experienced other, less common manifestations, including ear-related problems. Ear pain, pressure, and hearing loss have been reported by some COVID-19 patients, suggesting a potential connection between the virus and the auditory system."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Ear Pain:"), " Some individuals have reported experiencing COVID-related ear pain, which can be sharp, dull, or throbbing in nature. The cause of this ear pain is not entirely understood, but it could be related to the virus's impact on the sinuses and upper respiratory tract."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Ear Pressure:"), " Another frequently reported symptom is a feeling of pressure in the ears. This sensation may be accompanied by a feeling of stuffiness or congestion in the ear canals. This symptom is thought to be linked to the virus's ability to cause inflammation and congestion in the upper respiratory system, which can extend to the Eustachian tubes and the middle ear."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Hearing Loss:"), " The most concerning hearing-related symptom is hearing loss, which can range from mild to severe. While not every COVID-19 patient experiences hearing loss, there is evidence to suggest that the virus may damage the delicate structures of the inner ear. This damage can be temporary or, in some cases, result in long-lasting hearing impairment."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-mechanism-behind-hearing-related-symptoms",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-mechanism-behind-hearing-related-symptoms",
    "aria-label": "the mechanism behind hearing related symptoms permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Mechanism Behind Hearing-Related Symptoms"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The exact mechanism by which COVID-19 may cause hearing-related symptoms is not yet fully understood, but several theories have been proposed:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Inflammation and Immune Response:"), " COVID-19 is known to trigger a robust immune response, leading to widespread inflammation in various parts of the body. It is possible that this inflammation affects the delicate structures of the ear, leading to ear pain, pressure, and hearing loss."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Blood Clotting:"), " The virus has been associated with an increased risk of blood clot formation. In some cases, these blood clots may affect the blood supply to the ear, potentially leading to hearing loss."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.strong, null, "Direct Viral Invasion:"), " There is also evidence to suggest that the virus may directly infect cells in the ear, damaging them and leading to hearing problems."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-importance-of-addressing-hearing-related-issues",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-importance-of-addressing-hearing-related-issues",
    "aria-label": "the importance of addressing hearing related issues permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Importance of Addressing Hearing-Related Issues"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "While hearing-related symptoms may not be the most common manifestations of COVID-19, they are nonetheless significant and should not be overlooked. Hearing loss can have a profound impact on an individual's quality of life. It can affect communication, relationships, and overall well-being. Prompt diagnosis and treatment of these symptoms are crucial for managing and potentially mitigating their long-term effects."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "seek-professional-help-from-hearcom-hearing-experts",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#seek-professional-help-from-hearcom-hearing-experts",
    "aria-label": "seek professional help from hearcom hearing experts permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Seek professional help from hear.com hearing experts"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you or someone you know has experienced ear pain, pressure, or hearing loss in connection with a COVID-19 infection, it is essential to seek professional and immediate help. Audiologists and ear specialists can provide a thorough evaluation and recommend appropriate treatments or interventions to address these symptoms. Do not hesitate to reach out to a hearing professional to ensure that someone is taking care of your auditory health."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To get started on your journey to better hearing, contact ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), ", where our team of hearing care professionals can connect you with experienced hearing care specialists and help you regain control over your auditory health. Hearing loss is a challenge, but with the right support and expertise, you can navigate its complexities and enjoy a fulfilling, connected life. Do not let COVID-19-related hearing issues go unaddressed – reach out today to take the first step toward better hearing and an improved quality of life."), "\n", React.createElement(LandingPageCta, {
    copy: "Get free hearing expert advice",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
